<template>
  <div class="detail container space">
    <div class="img-wrapper">
      <img src="@/assets/brand/ks/ks_01.png" alt="">
      <img src="@/assets/brand/ks/ks_02.png" alt="">
      <img src="@/assets/brand/ks/ks_03.png" alt="">
      <img src="@/assets/brand/ks/ks_04.png" alt="">
      <img src="@/assets/brand/ks/ks_05.png" alt="">
      <img src="@/assets/brand/ks/ks_06.png" alt="">
      <img src="@/assets/brand/ks/ks_07.png" alt="">
      <img src="@/assets/brand/ks/ks_08.png" alt="">
    </div>
  </div>
</template>

<style lang="scss">
  
</style>

<script>
export default {
  data(){
    return {
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
 .detail {
    .img-wrapper {
      width: 100%;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 768px){
  .detail .img-wrapper {
    margin-top: 60px;
    padding: 0 15px;
  }
}
</style>